// extracted by mini-css-extract-plugin
export var ___gatsby = "bookTemplate-module--___gatsby--846fc";
export var category = "bookTemplate-module--category--d98d7";
export var cover = "bookTemplate-module--cover--6d9eb";
export var gatsbyAnnouncer = "bookTemplate-module--gatsby-announcer--d8394";
export var header = "bookTemplate-module--header--80611";
export var page = "bookTemplate-module--page--9e99f";
export var pageNumber = "bookTemplate-module--pageNumber--ff6b0";
export var section = "bookTemplate-module--section--1c2fe";
export var titlePage = "bookTemplate-module--titlePage--c33f8";
export var toc = "bookTemplate-module--toc--a285d";
export var tocCategory = "bookTemplate-module--tocCategory--82343";
export var tocPage = "bookTemplate-module--tocPage--bd632";
export var tocWrapper = "bookTemplate-module--tocWrapper--dca37";
export var toolbar = "bookTemplate-module--toolbar--c97bb";